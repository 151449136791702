import React from 'react';
import numberFormatter from 'number-formatter';
import PropTypes from 'prop-types';
import clientService from '../services/client';
import LoadingScreen from './LoadingScreen';

class PaymentFrequencySelectorComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading:false,
      data:null,
      selectedFrequency:this.props.exclude,
      defaultFreq:this.props.exclude
    }
  }

  componentDidMount(){
    const {invoice} = this.props;

    let output = {
      planCount:{total:0},
      price:{
        monthly:0,
        quarterly:0,
        yearly:0
      }
    };


    clientService.getAllPlans()
      .then(plans=>{
        plans.forEach(plan=>{
          let planKey = plan.name.trim().toLowerCase();
          let invoicePlan = invoice.products.filter(p=>p.name.indexOf(plan.name) > -1)[0] || null;
          if(invoicePlan){
            output.planCount[planKey] = invoicePlan.no_of_enrollees;
            output.planCount.total+=invoicePlan.no_of_enrollees;
            Object.keys(output.price)
              .forEach(k=>{
                output.price[k] = Number(output.price[k]) + (plan.price[k] * invoicePlan.no_of_enrollees);
              });
          }
        });

        this.setState({
          data: output
        })
      })
  }

  onChangeFrequency = (freq) => {
    this.setState({
      selectedFrequency:freq
    })
  };

  onChangeConfirmed = () => {
    this.setState({
      isLoading: true
    });
    const {user, invoice, onFrequencyChanged} = this.props;
    const {selectedFrequency} = this.state;
    clientService.changePaymentFrequency(user.credentials.accessToken, user.companyInfo.id, invoice.reference_code, selectedFrequency[0])
      .then(response=>{
        onFrequencyChanged(null,response);
      })
      .catch(e=>{
        onFrequencyChanged(e.message);
      })
  };

  render(){
    const {data, isLoading, selectedFrequency, defaultFreq} = this.state;

    if(isLoading || !data) return (
      <div style={{ position:"relative",width:"100%",height:"100px",minWidth:"300px"}}>
        <LoadingScreen/>
      </div>
    );

    return (
      <div>
        <p style={{fontSize: "1.6rem", color: "#212121", marginBottom: "2rem"}}>To change how often you pay, please select one of the options below</p>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between", margin: "2rem 0"}}>
          {Object.keys(data.price).map((freq,index)=>{
            return (
              <div key={index+freq}>
                <h3 style={{fontSize: "1.8rem", color: "#212121", marginBottom: "2rem"}}>{freq[0].toUpperCase()}{freq.slice(1)}</h3>
                <p style={{fontSize: "2.4rem", color: "#27ae60", fontWeight: "800"}}>&#8358;{numberFormatter("#,###.00",data.price[freq]) /** >= 4 ? getDiscountedPrice(data.price[freq],20) :  */ }</p>
                <p style={{fontSize: "1.6rem", color: "#212121", marginBottom: "2rem"}}>For {data.planCount.total} Plan(s)</p>
                <p style={{fontSize: "1.4rem", color: "#333", marginBottom: "1rem"}}>{
                  Object.keys(data.planCount).map((plan,index)=> {
                    if(plan === 'total') return null;
                    return <span key={plan+index}>
                        {`${data.planCount[plan]} ${plan} ${index !== Object.keys(data.planCount).length - 1 ? ',':''} `}
                      </span>
                  })
                }</p>
                <button onClick={()=>this.onChangeFrequency(freq)} className={`${selectedFrequency === freq /** || defaultFreq === freq **/? 'primary_button': 'primary_button secondary'}`}>{selectedFrequency === freq /** || defaultFreq === freq **/? 'Selected' : 'Select'}</button>
              </div>
            )
          })}
        </div>
        <div style={{display: selectedFrequency !== defaultFreq ? 'block': 'none'}}>
        <p style={{fontSize: "1.4rem", color: "#333", marginBottom: "1rem"}}>Click the 'Proceed' button below to change your payment frequency to {selectedFrequency}.</p>
        <p style={{fontSize: "1.4rem", color: "#333", marginBottom: "1rem"}}> Please note that this change will take effect at your next renewal.</p>
        <div className="button-header" style={{justifyContent:"center"}}>
            <button
              className="primary_button"
              onClick={this.onChangeConfirmed}>
              PROCEED
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PaymentFrequencySelectorComponent.propTypes = {
  invoice: PropTypes.object,
  user: PropTypes.object,
  onFrequencyChanged: PropTypes.func,
  exclude: PropTypes.string
};

export default PaymentFrequencySelectorComponent;